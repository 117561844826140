<template>
  <div
    class="subheader py-2 py-lg-4 subheader-solid"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center flex-wrap mr-1">
        <h5 class="text-dark font-weight-bold my-2 mr-5">

        </h5>
      </div>
      <div id="main-menu">
        <Menu></Menu>
      </div>
      <el-dropdown trigger="click">
        <el-button type="default" style="padding: 8px 11px;">
          <div style="display: flex">
            <el-avatar :size="25" :src="currentUser.avatar">
              <el-avatar class="w-100 h-100" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
            </el-avatar>
            <span class="pl-2" style="line-height: 28px;">{{currentUser.username}}<i class="el-icon-arrow-down el-icon--right"></i></span>
          </div>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><p @click="logout"><i class="fas fa-sign-out-alt"></i>ログアウト</p></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import {LOGOUT} from "@/core/services/store/auth.module";
import Menu from "@/view/layout/header/Menu.vue";
import * as constants from "@/core/config/constant";

export default {
  name: "KTSubheader",
  data() {
      return {
          urlLogin:{name: 'login'},
          user:null,
      }
  },
  components: {
    Menu
  },
  props: {
    breadcrumbs: Array,
    title: String
  },
  computed: {
    ...mapGetters(["layoutConfig", 'currentUser']),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    }
  },
    created() {
      if (this.currentUser.super_user != constants.SUPER_USER){
          this.urlLogin = {name: 'tenant_login', params: { tenant_dir: this.currentUser.tenant.tenant_dir }}
      }
    },
    methods: {
      logout() {
          this.$confirm('本当にログアウトしますか？', '確認', {
              confirmButtonText: 'OK',
              cancelButtonText: '閉じる',
              type: 'warning'
          }).then(() => {
              this.$store.dispatch(LOGOUT).then(response => {
                      this.notifySuccess(this.$t('LOGOUT_SUCCESS'))
                      let self = this
                      setTimeout(function () {
                          self.$router.push(self.urlLogin)
                      }, 500)
                  })
                  .catch(err => {
                      this.notifyError(err.message)
                  });
          }).catch(error => {
              this.$message({
                  type: 'info',
                  message: this.$t('LOGOUT_CANCELED')
              });
          });
      }
    }
};
</script>
