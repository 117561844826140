<template>
  <div id="wapper-menu-mobile">
    <div id="menu-mobile">
      <Menu></Menu>
    </div>
    <div id="layout-mask-menu-mobile" class="layout-mask-menu-mobile" @click="activeMenu()"></div>
  </div>
</template>

<script>
import Menu from "@/view/layout/header/Menu.vue";

export default {
  name: "MenuMobile",
  components: {
    Menu
  },
  props:{
    active:{
      type:Boolean,
      default:false
    }
  },
  created() {
  },
  methods: {
    activeMenu(){
      KTUtil.activeMenu();
    }
  }
}
</script>

<style scoped>

</style>
