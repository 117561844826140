<template>
  <ul class="breadcrumb breadcrumb-transparent main-menu font-weight-bold p-0 my-2">
    <li aria-haspopup="true" data-menu-toggle="hover"
        :class="['form-base-add','form-item-add','form-base-list',].includes(current_router_name)?'menu-item-active-header':''"
        class="menu-item">
      <a href="javascript:void(0)"
         class="btn btn-sm font-weight-bold font-size-base mr-10" @click="openSubMenu($event)">
        フォーム
      </a>
      <ul class="submenu">
        <li
            :class="['form-base-add',].includes(current_router_name)?'menu-item-active-header':''"
          >
          <router-link class="" :to="{ name: 'form-base-add'}" v-slot="{ href, navigate, isActive, isExactActive }">
            <a :href="href" class="item-submenu" @click="navigateRedirect('form-base-add',$event)">
              フォーム作成
            </a>
          </router-link>
        </li>
        <li
            :class="['form-item-add',].includes(current_router_name)?'menu-item-active-header':''"
            >
          <router-link class="" :to="{ name: 'form-item-add'}" v-slot="{ href, navigate, isActive, isExactActive }">
            <a :href="href" class="item-submenu" @click="navigateRedirect('form-item-add',$event)">
              フォーム項目定義
            </a>
          </router-link>
        </li>
        <li
            :class="['form-base-list',].includes(current_router_name)?'menu-item-active-header':''">
          <router-link class="" :to="{ name: 'form-base-list'}" v-slot="{ href, navigate, isActive, isExactActive }">
            <a class="item-submenu" :href="href" @click="navigateRedirect('form-base-list',$event)">
              フォーム検索</a>
          </router-link>
        </li>
      </ul>
    </li>
    <router-link class="" :to="{ name: 'form-answer-list'}" v-slot="{ href, navigate, isActive, isExactActive }">
        <li class="breadcrumb-item" aria-haspopup="true" data-menu-toggle="hover"
            :class="[
              isActive && 'menu-item-active-header',
              isExactActive && 'menu-item-active-header',
              ['form-base-detail'].includes(current_router_name)?'menu-item-active-header':''
            ]">
          <a :href="href" @click="navigateRedirect('form-answer-list',$event)"
             class="btn btn-sm font-weight-bold font-size-base mr-10 menu-item-active-header">回答検索</a>
        </li>
    </router-link>
    <li aria-haspopup="true" data-menu-toggle="hover"
        :class="['register-account','list-account'].includes(current_router_name)?'menu-item-active-header':''"
        class="menu-item">
      <a href="javascript:void(0)"
         @click="openSubMenu($event)"
         class="btn btn-sm font-weight-bold font-size-base mr-10 menu-item-active-header">
        アカウント
      </a>
      <ul class="submenu">
        <li
            :class="['register-account'].includes(current_router_name)?'menu-item-active-header':''"
            class="" aria-haspopup="true" data-menu-toggle="hover">
          <router-link class="" :to="{ name: 'register-account' }" v-slot="{ href, navigate, isActive, isExactActive }">
            <a :href="href" class="item-submenu" @click="navigateRedirect('register-account',$event)">アカウント登録</a>
          </router-link>
        </li>
        <li
            :class="['list-account'].includes(current_router_name)?'menu-item-active-header':''"
            class="" aria-haspopup="true" data-menu-toggle="hover">
          <router-link class="" :to="{ name: 'list-account'}" v-slot="{ href, navigate, isActive, isExactActive }">
            <a :href="href" class="item-submenu" @click="navigateRedirect('list-account',$event)">アカウント検索</a>
          </router-link>
        </li>
      </ul>
    </li>
<!--      phase 2-->
    <li aria-haspopup="true" data-menu-toggle="hover"
        :class="['process-approval','process-approval-edit','list-approval'].includes(current_router_name)?'menu-item-active-header':''"
        class="menu-item">
      <a href="javascript:void(0)"
         @click="openSubMenu($event)"
         class="btn btn-sm font-weight-bold font-size-base mr-10 menu-item-active-header">
        承認プロセス
      </a>
      <ul class="submenu">
          <li
              :class="['process-approval'].includes(current_router_name)?'menu-item-active-header':''"
              class="" aria-haspopup="true" data-menu-toggle="hover">
              <router-link class="" :to="{ name: 'process-approval' }" v-slot="{ href, navigate, isActive, isExactActive }">
                  <a :href="href" class="item-submenu" @click="navigateRedirect('process-approval',$event)">承認プロセス設定</a>
              </router-link>
          </li>
          <li
            :class="['list-approval'].includes(current_router_name)?'menu-item-active-header':''"
            class="" aria-haspopup="true" data-menu-toggle="hover">
          <router-link class="" :to="{ name: 'list-approval'}" v-slot="{ href, navigate, isActive, isExactActive }">
            <a :href="href" class="item-submenu" @click="navigateRedirect('list-approval',$event)">承認プロセス検索</a>
          </router-link>
        </li>
      </ul>
    </li>

    <li aria-haspopup="true" data-menu-toggle="hover"
        :class="['add-role','edit-role','list-role'].includes(current_router_name)?'menu-item-active-header':''"
        class="menu-item">
      <a href="javascript:void(0)"
         @click="openSubMenu($event)"
         class="btn btn-sm font-weight-bold font-size-base mr-10 menu-item-active-header">
        権限
      </a>
      <ul class="submenu">
        <li
            :class="['add-role'].includes(current_router_name)?'menu-item-active-header':''"
            class="" aria-haspopup="true" data-menu-toggle="hover">
          <router-link class="" :to="{ name: 'add-role' }" v-slot="{ href, navigate, isActive, isExactActive }">
            <a :href="href" class="item-submenu" @click="navigateRedirect('add-role',$event)">権限設定</a>
          </router-link>
        </li>
        <li
            :class="['list-role'].includes(current_router_name)?'menu-item-active-header':''"
            class="" aria-haspopup="true" data-menu-toggle="hover">
          <router-link class="" :to="{ name: 'list-role'}" v-slot="{ href, navigate, isActive, isExactActive }">
            <a :href="href" class="item-submenu" @click="navigateRedirect('list-role',$event)">権限検索</a>
          </router-link>
        </li>
      </ul>
    </li>
      <li aria-haspopup="true" data-menu-toggle="hover"
          :class="['form-tenant-add','tenant-list'].includes(current_router_name)?'menu-item-active-header':''"
          class="menu-item">
          <a href="javascript:void(0)"
             @click="openSubMenu($event)"
             class="btn btn-sm font-weight-bold font-size-base mr-10 menu-item-active-header">
              テナント
          </a>
          <ul class="submenu">
              <li
                  :class="['form-tenant-add'].includes(current_router_name)?'menu-item-active-header':''"
                  class="" aria-haspopup="true" data-menu-toggle="hover">
                  <router-link class="" :to="{ name: 'form-tenant-add' }" v-slot="{ href, navigate, isActive, isExactActive }">
                      <a :href="href" class="item-submenu" @click="navigateRedirect('form-tenant-add',$event)">テナント設定</a>
                  </router-link>
              </li>
              <li
                  :class="['tenant-list'].includes(current_router_name)?'menu-item-active-header':''"
                  class="" aria-haspopup="true" data-menu-toggle="hover">
                  <router-link class="" :to="{ name: 'tenant-list'}" v-slot="{ href, navigate, isActive, isExactActive }">
                      <a :href="href" class="item-submenu" @click="navigateRedirect('tenant-list',$event)">テナント検索</a>
                  </router-link>
              </li>
          </ul>
      </li>
      <router-link class="" :to="{ name: 'template'}" v-slot="{ href, navigate, isActive, isExactActive }">
          <li class="breadcrumb-item" aria-haspopup="true" data-menu-toggle="hover"
              :class="[
              isActive && 'menu-item-active-header',
              isExactActive && 'menu-item-active-header',
              ['template'].includes(current_router_name)?'menu-item-active-header':''
            ]">
              <a :href="href" @click="navigateRedirect('template',$event)"
                 class="btn btn-sm font-weight-bold font-size-base mr-10 menu-item-active-header">メール管理</a>
          </li>
      </router-link>
      <div class="submit-form-item refresh_page">
          <Dialog v-on:closeDialog="isRedirect=false" v-on:submit="redirectPage" v-bind:dialog="isRedirect"
                  :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OK_REFRESH_PAGE')" :showCancelButton="true" v-bind:btnCancel="$t('DIALOG.BTN_CANCLE_REFRESH_PAGE')">
              <template slot="header">
                  <div class="modal-header text-center">
                      <h4 style="width: 100%;">確認</h4>
                  </div>
              </template>
              <template slot="content">
                  <div class="textwidget">
                      {{ $t('DIALOG.TILE_REFRESH_PAGE') }}<br>
                      {{ $t('DIALOG.BODY_REFRESH_PAGE') }}
                  </div>
              </template>
          </Dialog>
      </div>
  </ul>
</template>

<script>
import {CREATE_FORM_BASE} from "@/core/services/store/formStore";
import {LIST_REDIRECT} from "@/core/services/store/confirmRedirectPage.module";
import {mapGetters,mapActions} from "vuex";
import Dialog from "@/view/components/Dialog";
export default {
    components: {
        Dialog
    },
    name: "Menu",

    data() {
      return {
          current_router_name:'',
          isRedirect:false,
          routeHistory:''
      }
    },

    watch:{
      $route (to, from){
        this.current_router_name = to.name;
      }
    },

    created: async function () {
          this.current_router_name = this.$route.name;

    },
      computed: {
          ...mapGetters(['dataListRedirect','currentUser']),
      },
      methods: {
          ...mapActions([LIST_REDIRECT]),
          navigateRedirect(route,event){
              event.preventDefault();
              if (this.dataListRedirect.form_item_redirect){
                  this.isRedirect=true
                  this.routeHistory=route
                  return false;
              }
              this.$router.push({
                  name: route,
              });
          },
          redirectPage(){
              let route = this.routeHistory;
              this.routeHistory = ''
              this.isRedirect=false
              this.listRedirect(0)
              this.$router.push({
                  name: route,
              });
          },
          openSubMenu(event){
              KTUtil.toggleSubMenu(event);
          }
      },


  }
</script>

