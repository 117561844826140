<template>
  <ul class="menu-nav">
      <router-link
          :to="{ name: 'staff-list'}"
          v-slot="{ href, navigate, isActive, isExactActive }"
      >
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
          >
              <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon fas fa-user-tie"></i>
                  <span class="menu-text"> スタッフ管理 </span>
              </a>
          </li>
      </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
